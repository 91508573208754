<template>
  <div v-if="!passwordChangeRequired">
    <div class="mb-3">
      <label for="username" class="form-label">Benutzername</label>
      <input
          id="username"
          class="form-control"
          type="text"
          v-model="form.username"
          @keyup.enter="login"
      />
    </div>
    <div class="mb-3">
      <label for="password" class="form-label">Passwort</label>
      <input
          type="password"
          class="form-control"
          id="password"
          v-model="form.password"
          @keyup.enter="login"
      />
    </div>

    <div class="mb-3">
      <button class="btn btn-primary text-white" @click="login">Anmelden</button>
    </div>
  </div>


  <div v-else>
    {{this.form}}
    <h1 class="display-6">Passwort ändern</h1>
    <p>
      Es ist erforderlich, dass Sie Ihr Passwort ändern. Bitte tragen Sie in den nachstehenden Feldern Ihr neues Passwort zweimal identisch ein.
    </p>
    <div class="mb-3">
      <label for="username" class="form-label">Passwort</label>
      <input
          id="newPassword"
          class="form-control"
          type="password"
          v-model="form.newPassword"
          @keyup.enter="changePassword"
      />
    </div>

    <div class="mb-3">
      <label for="username" class="form-label">Passwort wiederholen</label>
      <input
          id="newPasswordConfirm"
          class="form-control"
          type="password"
          v-model="form.newPasswordConfirm"
          @keyup.enter="changePassword"
      />
    </div>

    <div class="mb-3">
      <button class="btn btn-primary text-white" @click="changePassword">Passwort ändern</button>
    </div>
  </div>

  <div class="mb-3" v-if="form.error">
    <div class="alert alert-danger d-flex align-items-center" role="alert">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
      <div>
        {{ form.error }}
      </div>
    </div>
  </div>

  <div class="mb-3" v-if="form.message">
    <div class="alert alert-primary d-flex align-items-center" role="alert">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
      <div>
        {{ form.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthSignIn",

  data() {
    return {
      form: {
        username: "",
        password: "",
        error: "",
        message: "",
        newPassword: "",
        newPasswordConfirm: "",
      },

      passwordChangeRequired: false,

      user: {
        id: "",
        forename: "",
        surname: "",
        username: "",
        permissions: "",
        accessToken: "",
        isSuperuser: false,
      }
    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  mounted() {
    if (this.loggedIn) {
      this.$router.push({name: 'user-info'})
    }
  },

  methods: {
    login() {
      if (this.form.username !== "" && this.form.password !== "") {
        this.$store.dispatch("auth/login", { username: this.form.username, password: this.form.password })
        .then(() => {
          this.$router.push({name: 'user-info'})
        })
        .catch(reason => {
          this.passwordChangeRequired = reason.response.data.changePassword;

          if (!this.passwordChangeRequired) {
            this.form.error = reason.response.data.message
          }
        })
      }
    },

    changePassword() {
      if (this.form.newPassword === this.form.newPasswordConfirm) {
        this.$store.dispatch("auth/register", {
          username: this.form.username,
          password: this.form.password,
          newPassword: this.form.newPassword
        }).then(() => {
          this.passwordChangeRequired = false;
          this.form.username = ""
          this.form.password = ""

          this.form.message = "Bitte melden Sie sich jetzt mit Ihren neuen Zugangsdaten an."
          this.form.error = ""
          
        }).catch(reason => {
          this.form.error = reason.response.data.message
          this.form.message = ""
        })
      } else {
        this.form.error = "Die angegebenen Passwörter sind nicht identisch zueinander."
      }
    }
  }
}
</script>

<style scoped>

</style>